section.hero {
  position: relative;
  z-index: -1; // Needed for Hex Pattern psuedo-element
  border-top: 1px solid var(--hero-border-color, $gray-300);
  border-bottom: 1px solid var(--hero-border-color, $gray-300);
  transition: all $theme-toggle-transition-time ease-in-out;
  box-shadow: inset 0px 0px 4px 0px rgba(0, 0, 0, 0.7);

  // Animated Gradient Background
  background: var(--hero-gradient-background, $hero-light-gradient);
  background-size: 400%;
  animation: gradient 15s ease infinite;

  // Gradient Animation that sits behind the hex pattern
  @keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
  }
 
  // Hex Pattern Overlay
  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    background: url(../img/svg/hex_pattern.svg);
    mix-blend-mode: overlay;
    z-index: -1;
  }

  // Nested Headings
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    font-family: $accent-font;
    color: $white;
    text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.7);
    letter-spacing: 0.03em;
  }
}
