// CSS Variables

// Mixin to set CSS variables to dark mode values
@mixin theme-dark() {

  // Layout

  // Body
  --mp-body-bg: #{$dark-blue-light};
  --mp-headline-color: #{$flirt-light};
  --mp-text-color: #{$light-steel-blue};

  // Header
  --header-background-color: #{$dark-blue-light};
  --header-box-shadow-color: #{$black};

  // Modules

  // Hero
  --hero-gradient-background: #{$hero-dark-gradient};
  --hero-hex-overlay-color: #{$dark-blue};
  --hero-border-color: #{$dark-blue-lighter};

  // Social Media Links
  --social-links-background-color: #{$dark-blue};
  --social-links-text-color: #{$light-steel-blue};
  --social-links-icon-filter: grayscale(100%);
  --social-links-icon-opacity: 0.5;

  // Contact Form
  --contact-form-border-color: #{$light-steel-blue};
  --contact-form-background-color: #{$dark-blue};
  --contact-form-submit-button-background-color: #{$flirt-light};
  --contact-form-submit-button-text-color: #{$light-steel-blue};

  // Components

  // Logo
  --logo-stroke: #{$dark-blue-lighter};
  --logo-foreground: #{$flirt-light};
  --logo-background: #{$flirt-dark};
  --logo-text-fill: #{$light-steel-blue};

  // Theme Toggle
  --theme-toggle-switch-border: #{$flirt-dark};
  --theme-toggle-switch-fill: #{$flirt-light};
  --theme-toggle-border-color: #{$dark-blue-lighter};
  --theme-toggle-box-shadow-color: #{$gray-900};
  --theme-toggle-background-color: #{$dark-blue-lighter};
  --theme-toggle-switch-translate: translate(6px, 0);

  --theme-toggle-icon-color-light: #{$orange-dark};
  --theme-toggle-icon-opacity-light: 0;
  --theme-toggle-icon-transform-light: translate(0.25rem) rotate(40deg);

  --theme-toggle-icon-color-dark: #{$light-steel-blue};
  --theme-toggle-icon-opacity-dark: 1;
  --theme-toggle-icon-transform-dark: translate(0) rotate(0deg);

}

:root {
  & body {
    // Body class for turning on dark mode
    &.theme-dark {
      @include theme-dark();
    }

    // If the default theme has been set by the theme toggle switch, don't load dark mode.
    &:not(.theme-default) {
      // Otherwise, load dark mode by default if users color scheme preference is set to dark.
      @media (prefers-color-scheme: dark) {
        @include theme-dark();
      }
    }
  }
}
