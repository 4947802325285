// CSS Variables

// Mixin to set CSS variables to dark mode values
@mixin theme-default() {

  // Layout

  // Body
  --mp-body-bg: #{$white};
  --mp-headline-color: #{$orange-dark};
  --mp-text-color: #{$usafa-blue};

  // Header
  --header-background-color: #{$white};
  --header-box-shadow-color: #{$gray-400};

  // Modules

  // Hero
  --hero-gradient-background: #{$hero-light-gradient};
  --hero-hex-overlay-color: #{$orange-dark};
  --hero-border-color: #{$gray-300};

  // Social Media Links
  --social-links-background-color: #{$alice-blue};
  --social-links-text-color: #{$usafa-blue};
  --social-links-icon-filter: grayscale(0%);
  --social-links-icon-opacity: 1;

  // Contact Form
  --contact-form-border-color: #{$usafa-blue};
  --contact-form-background-color: #{$gray-100};
  --contact-form-submit-button-background-color: #{$orange-dark};
  --contact-form-submit-button-text-color: #{$white};

  // Components

  // Logo
  --logo-stroke: #{$black};
  --logo-foreground: #{$orange-light};
  --logo-background: #{$orange-dark};
  --logo-text-fill: #{$usafa-blue};

  // Theme Toggle
  --theme-toggle-switch-border: #{$orange-dark};
  --theme-toggle-switch-fill: #{$orange-light};
  --theme-toggle-border-color: #{$gray-400};
  --theme-toggle-box-shadow-color: #{$gray-300};
  --theme-toggle-background-color: #{$gray-200};
  --theme-toggle-switch-translate: translate(33px, 0);

  // Theme Toggle Light Icon
  --theme-toggle-icon-color-light: #{$orange-dark};
  --theme-toggle-icon-opacity-light: 1;
  --theme-toggle-icon-transform-light: translate(0) rotate(0deg);

  // Theme Toggle Dark Icon
  --theme-toggle-icon-color-dark: #{$light-steel-blue};
  --theme-toggle-icon-opacity-dark: 0;
  --theme-toggle-icon-transform-dark: translate(-0.25rem) rotate(-40deg);

}

// Set as default theme/mode + body class for toggling styles
:root,
.theme-default {
  @include theme-default();
}
