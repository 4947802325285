// Section Wrapper
.social-wrapper {

    padding-top: 70px;
    padding-bottom: 70px;
    background: var(--social-links-background-color, $gray-700);
    transition: background $theme-toggle-transition-time ease-in-out;

    // MEDIUM BREAKPOINT
    @include breakpoint-up(md) {

        padding-top: 3rem;
        padding-bottom: 3rem;

    }

}//.social-wrapper

// Grid Container
.social-grid {

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    // OVERRIDE DEFAULT CONTAINER STYLES
    &.container {

        margin: -35px auto 0 auto;

        // LARGE BREAKPOINT
        @include breakpoint-up(lg) {

            margin: 0 auto;

        }

    }

    // SOCIAL WRAPPER
    .social {

        width: 50%;
        height: auto;
        text-align: center;
        margin-top: 35px;

        // SE BREAKPOINT
        @include breakpoint-up(se) {

            width: (100% / 3);

        }

        // LARGE BREAKPOINT
        @include breakpoint-up(lg) {

            width: (100% / 6);
            margin-top: 0;
            flex-wrap: nowrap;

        }

        // LINK WRAPPER
        .social-link {

            display: flex;
            flex-direction: column;
            width: max-content;
            margin: 0 auto;
            text-align: center;
            text-decoration: none;

            // HOVER & FOCUS STATE
            &:hover {

                // ICON STATE
                .social-icon {
                    filter: grayscale(0%);
                    opacity: 1;
                }

            }//:hover

            // FOCUS STATE
            &:focus {

                outline: 1px solid $orange;

            }//:focus

        }//.social-link

        // ICONS
        .social-icon {

            width: 100%;
            max-width: 50px;
            height: auto;
            margin: 0 auto;

            // GRAYSCALE -> COLOR TRANSITION
            transition: filter $theme-toggle-transition-time ease-in-out;
            filter: var(--social-links-icon-filter, grayscale(100%));
            transition: opacity $theme-toggle-transition-time ease-in-out;
            opacity: var(--social-links-icon-opacity, 0.5);

        }

        // TEXT
        .social-title {
            
            margin-top: 20px;
            font-size: em(11);
            color: var(--social-links-text-color, $usafa-blue);
            text-transform: uppercase;

        }//.social-title

    }//.social

}//.social-grid
