button {
    // Basic styles
    display: block;
    background-image: linear-gradient(to right, #F09819 0%, #FF512F  100%);
    border: 0;
    box-sizing: border-box;
    padding: 1em 2em;
    cursor: pointer;

    // Using inset box-shadow instead of border for sizing simplicity
    box-shadow: inset 0 0 0 1px transparent;
    color: #fff;
    font-size: inherit;
    font-weight: 700;

    // Required, since we're setting absolute on pseudo-elements
    position: relative;

    &::before, &::after {
        box-sizing: inherit;
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
    }


    &.draw {
        transition: color 0.1s;

        &::before,
        &::after {
            // Set border to invisible, so we don't see a 4px border on a 0x0 element before the transition starts
            border: 2px solid transparent;
            width: 0;
            height: 0;
        }

        // This covers the top & right borders (expands right, then down)
        &::before {
            top: 0;
            left: 0;
        }

        // And this the bottom & left borders (expands left, then up)
        &::after {
            bottom: 0;
            right: 0;
        }
        
        &:hover {
            color: #fff;
        }

        // Hover styles
        &:hover::before,
        &:hover::after {
            width: 100%;
            height: 100%;
        }

        &:hover::before {
            border-top-color: rgba(0,0,0, 0.25); // Make borders visible
            border-right-color: rgba(0,0,0, 0.25);
            transition:
            width 0.1s ease-out, // Width expands first
            height 0.1s ease-out 0.1s; // And then height
        }

        &:hover::after {
            border-bottom-color: rgba(0,0,0, 0.25); // Make borders visible
            border-left-color: rgba(0,0,0, 0.25);
            transition:
            border-color 0s ease-out 0.2s, // Wait for ::before to finish before showing border
            width 0.1s ease-out 0.2s, // And then exanding width
            height 0.1s ease-out 0.3s; // And finally height
        }

    }

}