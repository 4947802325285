@charset "UTF-8";
////
/// @group colors
////

/*
BLACK, WHITE, AND GRAYS
≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡ */

$black:       #000 !default;
$white:       #fff !default;
$gray-100:    #f8f9fa !default;
$gray-200:    #e9ecef !default;
$gray-300:    #dee2e6 !default;
$gray-400:    #ced4da !default;
$gray-500:    #adb5bd !default;
$gray-600:    #6c757d !default;
$gray-700:    #495057 !default;
$gray-790:    #333333 !default;
$gray-800:    #343a40 !default;
$gray-900:    #212529 !default;

/*
COLOR WHEEL
≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡ */

$blue:        #0d6efd !default;
$indigo:      #6610f2 !default;
$purple:      #6f42c1 !default;
$pink:        #d63384 !default;
$red:         #dc3545 !default;
$orange:      #fd7e14 !default;
$yellow:      #ffc107 !default;
$green:       #198754 !default;
$teal:        #20c997 !default;
$cyan:        #0dcaf0 !default;

/*
LIGHT MODE COLORS & GRADIENTS
≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡ */

$mastrianni-orange: #ed6c24;
$orange-dark: hsl(30, 100%, 47%) !default;
$orange-light: hsl(38, 98%, 48%) !default;
$usafa-blue: hsl(207, 100%, 29%) !default;
$alice-blue: hsl(215, 100%, 88%) !default;
$hero-light-gradient: linear-gradient(145deg, #ff8900 0%, #ed4023 51%, #edbe22 100%);

/*
DARK MODE COLORS & GRADIENTS
≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡ */

$cyber-grape: hsl(264, 35%, 39%) !default;
$dark-blue: hsl(229, 51%, 8%) !default;
$dark-blue-light: hsl(232, 52%, 11%) !default;
$dark-blue-lighter: hsl(232, 43%, 30%) !default;
$flirt-light: hsl(322, 75%, 39%) !default;
$flirt-dark: hsl(322, 85%, 21%) !default;
$light-steel-blue: hsl(214, 41%, 78%) !default;
$hero-dark-gradient: linear-gradient(134deg, #532B8A 0%, #0B0845 49%, #70134E 100%);

/*
DESIGN COLORS
≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡ */

/// Primary color
/// @type colors-color
$primary:       $mastrianni-orange !default;

/// Secondary color
$secondary:     $gray-600 !default;

/// This color is used when indicating to users that an action was successful.
$success:       $green !default;

/// This color is used when displaying a notification that does not require the user to take additional action.
$info:          $cyan !default;

/// This color is used when displaying a warning notification.
$warning:       $yellow !default;

/// This color is used when displaying an error notification.
$error:         $red !default;