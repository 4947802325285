// SKILLS CONTAINER
.skills-container {

    // HEADINGS
    .skill-category {
        font-size: em(22);
        line-height: 1.3;
        margin-bottom: 10px;
        text-align: left;
        

        // MEDIUM BREAKPOINT
        @include breakpoint-up(md) {
            text-align: center;
        }
    }

    // SKILLS GRID
    .skills-grid {
        width: calc(100% + 20px);
        height: auto;
        display:flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: flex-start;
        margin: -10px;

        // SMALL BREAKPOINT
        @include breakpoint-up(md) {
            justify-content: center;
        }

        // HEADINGS
        & + .skill-category {
            margin-top: map-get($section-padding, 'mobile-top');

            // MEDIUM BREAKPOINT
            @include breakpoint-up(md) {
                margin-top: map-get($section-padding, 'desktop-top') * 0.5;
            }
        }

        // SKILL TILES
        .skill {
            width: 50%;
            height: auto;
            padding: 10px;

            // XSMALL BREAKPOINT
            @include breakpoint-up(se) {
                width: 25%;
            }

            // SMALL BREAKPOINT
            @include breakpoint-up(sm) {
                width: 20%;
            }

            // LARGE BREAKPOINT
            @include breakpoint-up(lg) {
                width: 15%;
            }

            // XLARGE BREAKPOINT
            @include breakpoint-up(xl) {
                width: 10%;
            }

            // SVG TILES
            svg {
                width:100%;
                height: 100%;
            }
        }
        
    }

}