html {
  // Font-size should be set to 16px by default
  font-size: 16px;
  // Font-size should adjust between being 16px and 22px up to the LG breakpoint.
  @include breakpoint-up(se) {
    font-size: calc(16px + 6 * ((100vw - 320px) / 680));
  }
  // Font-size should be 22px above the lg breakpoint.
  @include breakpoint-up(lg) {
    font-size: 22px;
  }
} //html

body {
  font-family: $default-font;
  font-weight: $regular;
  color: var(--mp-text-color, $black);
  font-size: 16px;
  font-style: normal;
  background-color: var(--mp-body-bg, $white);
  transition: background-color $theme-toggle-transition-time ease-in-out;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
}

// TYPOGRAPHY MIXINS

@mixin titleLarge() {
  font-family: $headline-font;
  font-weight: $regular;
  font-style: normal;
  color: $white;
  transition: color $theme-toggle-transition-time ease-in-out;

  // SMALL BREAKPOINT
  @include breakpoint-up(sm) {
    font-size: em(55);
    line-height: 1.2em;
  }
}

@mixin title() {
  font-family: $headline-font;
  font-weight: $regular !important;
  font-style: normal;
  font-size: em(30);
  line-height: 1.2em;
  transition: color $theme-toggle-transition-time ease-in-out;

  & + p {
    margin-top: 15px;
  }

  .subtitle + & {
    margin-top: 15px;
  }

  // SMALL BREAKPOINT
  @include breakpoint-up(sm) {
    font-size: em(40);

    .subtitle + & {
      margin-top: 25px;
    }

    & + p {
      margin-top: 25px;
    }
  }
}

@mixin titleSmall() {
  @include title;
  font-size: em(22);
  line-height: 1.3em;

  & + p,
  & + .subtitle {
    margin-top: 15px;
  }

  // SMALL BREAKPOINT
  @include breakpoint-up(sm) {
    font-size: em(30);
  }
}

@mixin subtitle() {
  font-family: $headline-font;
  font-size: 15px;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  font-weight: $bold;
  transition: color $theme-toggle-transition-time ease-in-out;

  & + .cardTitle,
  & + .titleSmall {
    margin-top: 12px;
  }

  & + p {
    margin-top: 25px;
  }
}

@mixin paragraph() {
  font-size: em(17);
  letter-spacing: 0.01em;
  line-height: 1.6em;
  font-weight: $regular;
  font-style: normal;
  transition: color $theme-toggle-transition-time ease-in-out;

  & + .subtitle {
    margin-top: 20px;
  }

  // SMALL BREAKPOINT
  @include breakpoint-up(sm) {
    font-size: em(19);
  }
}

@mixin paragraphSmall() {
  font-size: em(17);
  letter-spacing: 0.01em;
  line-height: 1.45em;
  transition: color $theme-toggle-transition-time ease-in-out;

  & + .btn {
    margin-top: 20px;
  }
}

// TYPOGRAPHY CLASSES

h1,
h2 {
  @include title;
}

h3 {
  @include titleSmall;
}

h4 {
  @include titleSmall;
}

h5,
h6 {
  @include subtitle;
}

.titleLarge {
  @include titleLarge;
}

.title {
  @include title;
}

.titleSmall {
  @include titleSmall;
}

.subtitle {
  @include subtitle;
}

.paragraph {
  @include paragraph;
}

.paragraphSmall {
  @include paragraphSmall;
}

p,
ul li,
ol li {
  @include paragraph;

  * + & {
    margin-top: 20px;
  }
  & + * {
    margin-top: 20px;
  }

  & + h1,
  & + h2,
  & + h3,
  & + h4,
  & + h5,
  & + h6,
  & + a {
    margin-top: 25px;
  }
}

.center {
  text-align: center;
}

strong {
  font-weight: $bold;
}

.color-orange {
  color: $mastrianni-orange;
}

.color-headline {
  color: var(--mp-headline-color, $orange-dark);
}
