@charset "UTF-8";
////
/// FUNCTIONS
/// @group Base
////

/// Utility function that converts pixels to em's.
///
///
/// @example scss - SASS Usage
///   p {
///      font-size: em(16);
///   }
///
/// @example css - CSS Output
///   p {
///      font-size: 1em;
///   }
/// @since 0.0.1
///

@function em($pixels, $context: $spacer) {
  @return #{$pixels/$context}em;
}
