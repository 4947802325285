// Section
section.contact {
    padding-top: 0;
}

// Form
.contact-form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin: -1rem;

    // Large breakpoint
    @include breakpoint-up(lg) {
        margin: -2rem;
    }   

    // Form Field
    & .contact-form-field {
        width: 100%;
        height: auto;
        padding: 1rem;

        // Add padding
        & + .contact-form-field:not(.w50) {
            margin-top: 1rem;
        }

        // 50% Width
        &.w50 {
            // Large breakpoint
            @include breakpoint-up(lg) {
                width: 50%;
            }   
        }

        // Center content
        &.text-center {
            text-align: center;
        }

    }

    // Form Field Group
    & .contact-form-field-group {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: 100%;
        height: auto;

        // Large breakpoint
        @include breakpoint-up(lg) {
            flex-direction: row;
        }

        & + .contact-form-field, & + .contact-form-field-group {
           margin-top: -0.5rem;
        }
    }

    // Notices & Feedback to the user
    .contact-form-notice {
        width: 100%;
        height: auto;
        padding: 1rem;
        text-align: center;

        // Success State
        &.success {
            h6 {
                color: $success;
            }
        }

        // Error State
        &.error {
            h6 {
                color: $error;
            }
        }

        // Error State
    
    }

    // Labels
    label {
        @include subtitle;
        font-size: 0.7em;
        & + input, & + textarea {
            margin-top: 0.5rem;
        }
    }

    // Text Inputs, Email Inputs + Textareas
    input[type=text], input[type=email], textarea {
        @include subtitle;
        width: 100%;
        height: auto;
        font-size: 0.7em;
        padding: 0.5rem;
        background-color: var(--contact-form-background-color, $dark-blue-lighter);
        border: 1px solid var(--contact-form-border-color, $usafa-blue);
        color: var(--mp-text-color, $usafa-blue);
        transition: color 0.25s ease, border 0.25s ease;

        // Focus State
        &:focus {
            border-color: var(--mp-text-color, $usafa-blue);
            outline: none;
        }

        // State when user has entered a value
        &:not([value=""]):not(:focus) {
            color: var(--mp-headline-color, $orange-dark);
            border-color: var(--mp-headline-color, $orange-dark);
        }

        // State when user has not entered a value
        &[value=""] {
            color: var(--mp-text-color, $usafa-blue);
        }

        // Add margin if a label is nested after an input or a textarea
        & + label {
            margin-top: 1rem;
        }

    }

    // Google reCaptcha
    .g-recaptcha {
        margin: 0 auto;
        .rc-anchor-dark {
            background-color:turquoise;
        }
    }

    // Submit Button
    input[type=submit] {
        min-width: 320px;
        padding: 0.5rem;
        border: 2px solid var(--contact-form-submit-button-background-color, $orange-dark);
        color: var(--contact-form-submit-button-background-color, $orange-dark);
        background-color: transparent;
        border-radius: 6rem;
        cursor: pointer;
        transition: background-color $theme-toggle-transition-time ease-in-out, color $theme-toggle-transition-time ease-in-out;
        text-transform: uppercase;
        font-family: $headline-font;

        // Hover + Focus State
        &:hover, &:focus {
            background-color: var(--contact-form-submit-button-background-color, $orange-dark);
            color: var(--contact-form-submit-button-text-color, $white);
            outline: none;
        }
    }

}