////
/// @group Globals
/// @type variable
////

// SPACER
/// @type variable
$spacer: 16;

// GUTTER
/// @type variable
$gutter: 3.125rem;

// FONTS
$accent-font: "Blank River", sans-serif;
$headline-font: "azo-sans-web", sans-serif;
$default-font: "mokoko", sans-serif;
$regular: 400;
$medium: 500;
$bold: 700;

// SECTION PADDING
/// Defines defaults for section padding globally.
/// @type map
$section-padding: (
  desktop-top: $gutter * 1.5,
  desktop-sides: $gutter,
  mobile-top: 70px,
  mobile-sides: 25px,
);

// CONTAINERS
/// Defines default container size, and additional container sizes.
/// @type map
$container-sizes: (
  x-small: 550px,
  small: 750px,
  default: 1200px,
  large: 1400px,
);

// BREAKPOINTS
/// Defines pixel values for breakpoints, and assigns them to a key.
/// @type map
$breakpoints: (
  xs: 0px,
  se: 375px,
  sm: 600px,
  md: 768px,
  tab: 901px,
  lg: 1025px,
  xl: 1201px,
  xxl: 1401px,
);

// Component: ThemeToggle
/// How long should light mode and dark mode take to switch between modes? */
$theme-toggle-transition-time: 0.25s;