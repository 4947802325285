section {

    // Include section padding defaults
	@include section-padding;

    // Container
    .container {
		// REGULAR CONTAINER
		width: map-get($container-sizes, 'default');
		max-width: 100%;
        margin: 0 auto;

		// ADDITIONAL CONTAINER SIZES
		@each $size, $width in $container-sizes {
			// ADDS CLASS
			&.#{$size} {
				width: $width;
			}
		}

		&.x-small{
			text-align: center;
		}

        &.large{
            .centered{
                width:750px;
                margin:0 auto;
                max-width: 100%;
            }
		}
		
		&.small + .container, .x-small + .container {

			// DEFAULT
			margin-top: map-get($section-padding, 'mobile-top') * 0.5;

			// SMALL BREAKPOINT
			@include breakpoint-up(sm) {
				margin-top: map-get($section-padding, 'desktop-top') * 0.5;
			}

		}

    }//.container

}//section

.centered {
    text-align: center;
}