@charset "UTF-8";
// MIXINS

////
/// @group Base
////

/// Wrap SASS in a media query with a min-width of $bp.
///
/// @since 0.0.1
/// @param {string} $bp - Key name for a breakpoint in the $breakpoints map
///
/// @example scss - SASS Usage
///   @include breakpoint-up(lg) {
///      // SASS HERE
///   }
///
/// @example css - CSS Output
///   @media only screen and (min-width: 1024px) {
///     // CSS HERE
///   }
///

@mixin breakpoint-up($bp) {

    // CHECK IF $BP IS A KEY IN THE $BREAKPOINT MAP
    @if(map-get($breakpoints, $bp) == null) {
        @error "You passed an invalid breakpoint to breakpoint-up(), use a breakpoint key from the $breakpoints map in _variables.";
    }

    // OUTPUT
    @media only screen and (min-width: map-get($breakpoints, $bp)) {
        @content;
    }

}

/// Wrap SASS in a media query with a max-width of $bp.
///
/// @since 0.0.1
/// @param {string} $bp - Key name for a breakpoint in the $breakpoints map
///
/// @example scss - SASS Usage
///   @include breakpoint-down(lg) {
///      // SASS HERE
///   }
///
/// @example css - CSS Output
///   @media only screen and (max-width: 1024px) {
///     // CSS HERE
///   }
///

@mixin breakpoint-down($bp) {

    // CHECK IF $BP IS A KEY IN THE $BREAKPOINT MAP
    @if(map-get($breakpoints, $bp) == null) {
        @error "You passed an invalid breakpoint to breakpoint-up(), use a breakpoint key from the $breakpoints map in _variables.";
    }

    // OUTPUT
    @media only screen and (max-width: map-get($breakpoints, $bp - 1)) {
        @content;
    }
    
}

/// Outputs default padding styles for sections
///
/// @since 0.0.1
/// @example scss - SASS Usage
///   section {
///
///     @include section-padding();
///
///   }
///
/// @example css - CSS Output
///   section {
///
///      padding: 70px 25px;
///
///      // SMALL BREAKPOINT
///      @media only screen and (max-width: 768px)
///         padding: 3rem 2rem;
///      }
///
///   }
///

@mixin section-padding(){
    
    // DEFAULT
    padding: map-get($section-padding, 'mobile-top') map-get($section-padding, 'mobile-sides');

    // MEDIUM BREAKPOINT
    @include breakpoint-up(md) {
        padding: map-get($section-padding, 'desktop-top') map-get($section-padding, 'desktop-sides');
    }

}