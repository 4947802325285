// Toggle Wrapper
.toggle {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  width: 64px;
  height: 32px;
  border: 1px solid var(--theme-toggle-border-color, $gray-300);
  border-radius: 6rem;
  background-color: var(--theme-toggle-background-color, $gray-200);
  cursor: pointer;
  transition: $theme-toggle-transition-time all ease-in-out;
  box-shadow: inset 0px 0px 2px 0px var(--theme-toggle-border-color, $black);
  
  // Focus State
  &:focus {
    outline: 1px solid var(--mp-headline-color, $orange-dark);
  }

  // Circle Switch
  .switch {
    display: block;
    position: absolute;
    width: 22px;
    height: 22px;
    border-radius: 100rem;
    background-color: var(--theme-toggle-switch-fill, $orange-light);
    border: 2px solid var(--theme-toggle-switch-border, $orange-dark);
    transition: $theme-toggle-transition-time all ease-in-out;
    transform: var(--theme-toggle-switch-translate, translate(33px, 0));
    z-index: 1;
  }

  // Light Mode + Dark Mode Icons Shared Styles
  .icon-light-mode,
  .icon-dark-mode {
    position: absolute;
    top: 0px;
    width: 20px;
    height: 30px;
    margin: 0 6px;
    transform-origin: 50% 50%;
    transition: opacity 0.05s ease-in, transform $theme-toggle-transition-time ease-in;
    svg {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      path {
        transition: $theme-toggle-transition-time fill ease-in-out;
      }
    }
  }

  // Light Mode Icon
  .icon-light-mode {
    left: 0px;
    transform: var(--theme-toggle-icon-transform-light, translate(0.25rem) rotate(0deg));
    opacity: var(--theme-toggle-icon-opacity-light, 1);
    svg {
      path {
        fill: var(--theme-toggle-icon-color-light, $orange-light);
      }
    }
  }

  // Dark Mode Icon
  .icon-dark-mode {
    right: 0px;
    transform: var(--theme-toggle-icon-transform-dark, translate(-0.25rem) rotate(-40deg));
    opacity: var(--theme-toggle-icon-opacity-dark, 0);
    svg {
      path {
        fill: var(--theme-toggle-icon-color-dark, $light-steel-blue);
      }
    }
  }

}