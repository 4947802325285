svg.logo {
    position: relative;
    left: -5px;
    
    path {
        /* Logomark Stroke + Background Color Fill */
        &#logo-outer {
            fill: var(--logo-background, $orange-dark);
            fill-opacity: 0;
            transition: fill $theme-toggle-transition-time ease-in-out;
            stroke: var(--logo-stroke, $black);
            stroke-opacity: 0.15;
            stroke-dasharray: 1;
            stroke-dashoffset: 1;
            stroke-width: 0.05em;
            stroke-miterlimit: 100;
            stroke-linejoin: miter;
            stroke-linecap: butt;
            animation: logo-draw 0.7s linear 0s forwards,
            logo-blink 0.3s linear 0.7s forwards,
            logo-color-fill 0.3s linear 1s forwards;

            /* Accessibility: Disable animation if user prefers reduced motion */
            @media screen and (prefers-reduced-motion: reduce) {
            fill-opacity: 1;
            stroke-opacity: 1;
            stroke-dashoffset: 0;
            animation: none;
            }
        }

        /* Logomark Foreground Color Fill */
        &#logo-inner {
            fill: var(--logo-foreground, $orange-light);
            fill-opacity: 0;
            transition: fill $theme-toggle-transition-time ease-in-out;
            animation: logo-color-blink 0.3s linear 1.15s forwards;

            /* Accessibility: Disable animation if user prefers reduced motion */
            @media screen and (prefers-reduced-motion: reduce) {
            fill-opacity: 1;
            animation: none;
            }
        }

        /* Text Color Fill */
        &#logo-text {
            fill: var(--logo-text-fill, $usafa-blue);
            transition: fill $theme-toggle-transition-time ease-in-out;
        }
    }

}

/* Stroke Drawing Animation */
@keyframes logo-draw {
  0% {
    stroke-opacity: 0.15;
    stroke-dashoffset: 1;
  }
  99% {
    stroke-opacity: 1;
    stroke-dasharray: 1;
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dasharray: 0;
  }
}

/* Stroke Blinking Animation */
@keyframes logo-blink {
  0% {
    stroke-opacity: 1;
  }
  16% {
    stroke-opacity: 0;
  }
  33% {
    stroke-opacity: 1;
  }
  50% {
    stroke-opacity: 0;
  }
  66% {
    stroke-opacity: 1;
  }
  83% {
    stroke-opacity: 0;
  }
  100% {
    stroke-opacity: 1;
  }
}

/* Color Fill Animation */
@keyframes logo-color-fill {
  0% {
    fill-opacity: 0;
  }
  100% {
    fill-opacity: 1;
  }
}

/* Color Blink Animation */
@keyframes logo-color-blink {
  0% {
    fill-opacity: 0;
  }
  16% {
    fill-opacity: 1;
  }
  33% {
    fill-opacity: 0;
  }
  50% {
    fill-opacity: 1;
  }
  66% {
    fill-opacity: 0;
  }
  83% {
    fill-opacity: 1;
  }
  100% {
    fill-opacity: 1;
  }
}