body > header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: var(--header-background-color, $white);
  background-size: 23px 40px, cover;
  background-repeat: repeat, no-repeat;
  transition: background-color $theme-toggle-transition-time ease-in-out;
  text-align: center;
  padding: 10px 25px;
  user-select: none;

  // Logo
  .logo {
    width: 100%;
    height: auto;
    max-width: 135px;
    //filter: drop-shadow( 0px 0px 6px rgba(0, 0, 0, 0.5));
  }
}
