body > footer {
  padding-top: 70px;
  padding-bottom: 70px;
  text-align: center;

  // MEDIUM BREAKPOINT
  @include breakpoint-up(md) {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  // PARAGRAPH TAGS
  p {
    font-size: em(14);
    text-transform: uppercase;
  }

  // COPYRIGHT SYMBOL
  .copyright {
    font-size: em(16);
  }

  // MADE WITH LOVE
  .made-with-love {
    font-family: $headline-font;
    margin-top: 20px;

    // HEART ICON
    .heart {
      display: inline-block;
      position: relative;
      top: 2px;
      width: 14px;
      height: 14px;
      margin: 0 5px;
    }
  }
}
